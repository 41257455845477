<template>
  <van-nav-bar
    class="common-header"
    :class="{'bg': isBg}"
    @click-left="()=>{}">
    <template #left>
      <div class="flex-middle menu-btn" @click="showPopup = true" v-if="!isOrder">
        <img src="@/assets/images/icon_01.png" class="icon-menu">
      </div>
      <div class="flex-middle back-btn" @click="$goBack" v-else>
        <img src="@/assets/images/user_00.png" class="icon-menu">
      </div>
    </template>
    <template #right v-if="!isOrder">
      <div class="lang-box">
        <van-popover class="lang-wrap" v-model:show="showLang" theme="dark" :actions="langList" placement="bottom-end">
          <div class="lang-select">
            <div class="option" :class="{'active':locale === item.lang}" v-for="(item,index) in langList" :key="index" @click="onSelectLang(item)">{{item.text}}</div>
          </div>
          <template #reference>
            <div class="flex-middle menu-lang" :class="{'menu-lang-up': showLang}">
              <span v-if="locale === 'cht'">繁體中文</span>
              <span v-if="locale === 'en'">English</span>
              <span v-if="locale === 'hi'">भारत</span>
              <span v-if="locale === 'ja'">日本</span>
              <span v-if="locale === 'ko'">한국인</span>
              <span v-if="locale === 'es'">Español</span>
            </div>
          </template>
        </van-popover>
      </div>
    </template>
    <template #title>
      <span class="header-title">{{title}}</span>
    </template>
  </van-nav-bar>
  <van-popup v-model:show="showPopup" position="left" :style="{ width: '66%', height: '100%', background: '#1C3F45' }">
    <div class="popup-block">
      <div class="top">{{$t('我的')}} <span class="top-btn" @click="showPopup = false"><img src="@/assets/images/icon_01.png" class="icon-btn"></span></div>
      <div class="address-title">{{$t('链钱包地址')}}</div>
      <div class="address-cont">{{address}}
      </div>
      <div class="cell-row flex-middle" @click="$router.push({name: 'Index'})">
        <img src="@/assets/images/user_01.png" alt="" class="icon">
        <div class="flex-item">LP - ENE</div>
        <img src="@/assets/images/user_00.png" alt="" class="i-arrow">
      </div>
      <div class="cell-row flex-middle" @click="$router.push({name: 'Sgt'})">
        <img src="@/assets/images/user_02.png" alt="" class="icon">
        <div class="flex-item">LP - SGT</div>
        <img src="@/assets/images/user_00.png" alt="" class="i-arrow">
      </div>
       <div class="cell-row flex-middle" @click="$router.push({name: 'StakingSgt'})">
        <img src="@/assets/images/user_02.png" alt="" class="icon">
        <div class="flex-item">SGT - SGT</div>
        <img src="@/assets/images/user_00.png" alt="" class="i-arrow">
      </div>
      <div class="cell-row flex-middle" @click="$router.push({name: 'StakingEne'})">
        <img src="@/assets/images/user_02.png" alt="" class="icon">
        <div class="flex-item">SGT - ENE</div>
        <img src="@/assets/images/user_00.png" alt="" class="i-arrow">
      </div>
      
      <div class="cell-row flex-middle"  @click="openUrl('https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=')">
        <img src="@/assets/images/user_03.png" alt="" class="icon">
        <div class="flex-item">{{$t('PancakeSwap')}}</div>
        <img src="@/assets/images/user_00.png" alt="" class="i-arrow">
      </div>
      <div class="cell-row flex-middle" @click="Unopen">
        <img src="@/assets/images/user_04.png" alt="" class="icon">
        <div class="flex-item">{{$t('盲盒NFT')}}</div>
        <img src="@/assets/images/user_00.png" alt="" class="i-arrow">
      </div>
      <div class="cell-row flex-middle" @click="Unopen">
        <img src="@/assets/images/user_05.png" alt="" class="icon">
        <div class="flex-item">{{$t('NFT交易市场')}}</div>
        <img src="@/assets/images/user_00.png" alt="" class="i-arrow">
      </div>
      <div class="foot-box">
        <div class="foot-item" @click="openUrl('https://discord.gg/bf85pcYqY3')">
          <img src="@/assets/images/user_06.png" alt="" class="item-icon">
        </div>
        <div class="foot-item" @click="openUrl('https://twitter.com/singularityhubs')">
          <img src="@/assets/images/user_07.png" alt="" class="item-icon">
        </div>
        <div class="foot-item" @click="openUrl('https://medium.com/@SingularityHubs')">
          <img src="@/assets/images/user_08.png" alt="" class="item-icon">
        </div>
        <div class="foot-item" @click="openUrl('https://github.com/SingularityHubs/Singularity-code')">
          <img src="@/assets/images/user_09.png" alt="" class="item-icon">
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { reactive, toRefs, computed, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { Toast } from 'vant';
import { useI18n } from "vue-i18n";
import web3Data from "@/utils/initWeb3";
import { setLang } from '@/utils/i18n'
export default {
  props: {
    title: {
      type: String,
      defalut: ''
    },
    isBg: {
      type: Boolean,
      defalut: false
    },
    isOrder: {
      type: Boolean,
      defalut: false
    }
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const { proxy } = getCurrentInstance()
    const data = reactive({
      locale: computed(() => store.state.locale),
      address: computed(() => store.state.accounts[0]),
      showPopup: false,
      username: '',
      initWallet: () => {
        if (!data.address) {
          web3Data.initWeb3()
        }
      },
      showLang: false,
      langList: [
        { text: 'English', lang: 'en' },
        { text: '繁體中文', lang: 'cht' },
        { text: 'भारत', lang: 'hi' },
        { text: '日本', lang: 'ja' },
        { text: '한국인', lang: 'ko' },
        { text: 'Español', lang: 'es' }
      ],
      onSelectLang: (action) => {
        if (action.lang) {
          setLang(action.lang)
        }
        data.showLang = false
      },
      openUrl: (url) => {
        window.location.href = url;
      },
      Unopen: () => {
        Toast(t('開發中，敬請期待'))
      }
    })
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>
<style lang="less" scoped>
.common-header {
  background: #31717B!important;
  &.bg {
    background: url('~@/assets/images/bg01.png') no-repeat top center !important;
    background-size: 100% auto !important;
  }
}
.left-title {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}
.left-icon {
  .van-icon {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
  }
}
.wallet-btn {
  height: 26px;
  border-radius: 13px !important;
  font-size: 13px;
  padding: 0 15px;
  min-width: 82px;
  text-align: center;
}
.menu-btn {
  height: 100%;
  margin: 0 0 0 15px;
  .icon-menu {
    width: 20px !important;
    height: auto;
  }
}
.back-btn {
  height: 100%;
  margin: 0 0 0 3px;
  transform: rotateY(180deg);
  .icon-menu {
    width: 20px !important;
    height: auto;
  }
}
.menu-lang {
  font-size: 14px;
  color: #fff;
  padding-right: 15px;
  position: relative;
  &::after {
    content: '';
    width: 8px;
    height: 4px;
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 0;
    background: url('~@/assets/images/icon_04.png') no-repeat top center;
    background-size: 100% 100%;
  }
  &.menu-lang-up {
    &::after {
      background: url('~@/assets/images/icon_03.png') no-repeat top center;
      background-size: 100% 100%;
    }
  }
}
.lang-select {
  padding: 10px 0;
  .option {
    padding: 8px 16px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    &.active {
      color: #E28D0B;
    }
  }
}

.header-title {
  color: #fff;
}
.popup-block {
  .top {
    min-height: 46px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 16px 26px;
    position: relative;
    .top-btn {
      width: 20px;
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -15px;
      right: 16px;
      .icon-btn {
        width: 20px;
        height: auto;
        display: block;
      }
    }
  }
  .address-title {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    padding: 0 20px;
  }
  .address-cont {
    margin: 8px 12px 20px;
    background: rgba(255, 255, 255, 0.1);
    min-height: 32px;
    line-height: 20px;
    border-radius: 10px;
    padding: 5px 10px;
    color: rgba(255, 255, 255, 0.64);
    font-size: 13px;
    word-wrap: break-word;
  }
  .cell-row {
    height: 52px;
    padding: 0 12px;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    .icon {
      width: 22px;
      margin: 0 14px 0 9px;
    }
    .i-arrow {
      width: 14px;
    }
  }
  .foot-box {
    margin: 30px 20px 0;
    width: 120px;
    .foot-item {
      width: 40px;
      height: 40px;
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 15px;
      .item-icon {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}
</style>
<style lang="less">
.lang-wrap {
  .van-popover__arrow {
    color: rgba(21, 59, 65, 0.96) !important;
  }
  .van-popover__content {
    background: rgba(21, 59, 65, 0.96) !important;
  }
}
</style>
